<template>
  <section
    slot="pdf-content"
    class="pdf-content"
  >
    <section class="pdf-item">
      <b-container fluid>
        <b-card no-body>
          <b-row class="m-3">
            <b-col cols="12">
              <b-row>
                <b-col
                  cols="6"
                  class="text-center"
                >
                  <span class="font-weight-bolder">
                    TRƯỜNG ĐẠI HỌC HOA LƯ
                  </span>
                </b-col>

                <b-col
                  cols="6"
                  class="text-center"
                >
                  <span class="font-weight-bolder">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</span><br>
                  <span class="font-weight-bolder">Độc lập - Tự do - Hạnh phúc</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <hr class="mx-3">
                </b-col>
                <b-col cols="6">
                  <hr class="mx-3">
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="3" />
                <b-col
                  cols="6"
                  class="text-center px-2"
                >
                  <span class="font-weight-bolder">
                    BÁO CÁO KẾT QUẢ GIẢNG DẠY VÀ ĐIỀU KIỆN THI HỌC PHẦN
                  </span>
                </b-col>
                <b-col cols="3" />
              </b-row>
              <b-row class="mx-3 my-2">
                <b-col cols="12">
                  <p><em><u class="font-weight-bolder">Kính gửi:</u></em> - Lãnh đạo</p>
                  <p class="ml-4">
                    - Phòng Đào tạo và quản lý khoa học
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <p>Tên tôi là: </p>
                  <p>Đơn vị công tác: </p>
                  <p>Được phân công giảng dạy học phần: {{ data.subjectName }} </p>
                  <b-row>
                    <b-col cols="6">
                      <p>Số tiết: {{ data.totalCreditNum }}</p>
                    </b-col>
                    <b-col cols="6">
                      <p>Mã lớp độc lập: {{ data.creditClassCode }}</p>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <p>Học kỳ: {{ teachingExam ? teachingExam.semesterCode : '' }}</p>
                    </b-col>
                    <b-col cols="6">
                      <p>Năm học: {{ teachingExam ? teachingExam.schoolYear : '' }}</p>
                    </b-col>
                  </b-row>
                  <p>Đã hoàn thành chương trình theo quy định vào ngày</p>
                  <p>Số học viên đủ điều kiện dự thi học phần: {{ teachingExam ? teachingExam.eligible : 0 }}</p>
                  <p>Số học viên không đủ điều kiện dự thi học phần: {{ teachingExam ? teachingExam.notEligible : 0 }}</p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <div class="text-right">
                    <em>Ninh Bình, ngày {{ today.date }} tháng {{ today.month }} năm {{ today.year }}</em>
                    <p class="font-weight-bolder mr-4">
                      CÁN BỘ GIẢNG DẠY
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </section>

    <template v-if="studentsIneligible.length > 0">
      <div class="html2pdf__page-break" />

      <section class="pdf-item">
        <b-container fluid>
          <b-card no-body>
            <b-row class="m-3">
              <b-col cols="12">
                <b-row>
                  <b-col
                    cols="12"
                    class="text-center"
                  >
                    <span class="font-weight-bolder">
                      DANH SÁCH HỌC VIÊN KHÔNG ĐỦ ĐIỀU KIỆN DỰ THI
                    </span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <b-table
                      bordered
                      :items="studentsIneligible"
                      :fields="fields"
                      :head-variant="null"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
      </section>
    </template>
  </section>
</template>

<script>
import {
  BCard, BCol, BContainer, BRow, BTable,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PdfContent',
  components: {
    BCard,
    BCol,
    BContainer,
    BRow,
    BTable,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        totalCreditNum: 0,
        creditClassCode: '',
        subjectName: '',
      }),
    },
  },
  data() {
    return {
      fields: [
        { key: 'code', label: 'Mã học viên', thClass: 'text-center' },
        { key: 'name', label: 'Họ tên học viên', thClass: 'text-center' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teachingExam: 'reportTeachingExamCondition/teachingExam',
      studentsIneligible: 'reportTeachingExamCondition/studentsIneligible',
    }),
    today() {
      const date = new Date()
      return {
        date: `0${date.getDate()}`.slice(-2),
        month: `0${date.getMonth() + 1}`.slice(-2),
        year: date.getFullYear(),
      }
    },
  },
}
</script>

<style lang="scss">
.pdf-content {
  width: 100%;
  font-family: "Times New Roman", Times, serif !important;
  font-size: 18px;
  color: #000000;
}
</style>
